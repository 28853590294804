.logo{
	padding-top: 4px;
	display: block;
	&__img{
		@include responsive-img();
		@include media-max($break_lg) {
			margin: 0 auto;
			padding: 6px 0;
		}
	}
}