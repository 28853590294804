.content-panel{
	$selector: &;
	color: $text-color;
	&__title{
		font-size: rem(20px);
		font-weight: bold;
		margin-bottom: 16px;
		position: relative;
		&::before{
			position: absolute;
			left: 0;
			bottom: -8px;
			content: "";
			height: 1px;
			background-color: #adabab;
			width: 230px;
		}
	}

	&__subtitle{
		margin-top: -12px;
	}


	&__date{
		color: #818080;
		font-size: rem(10px);
	  margin-bottom: 10px;
    margin-top: -14px;
    display: inline-block;
	}

	&__category{
		color: #818080;
		font-size: rem(10px);
		display: inline-block;
		position: relative;
		margin-left: 20px;
		margin-top: -14px;

		a{
			color: #818080;
			font-size: rem(10px);

			&:hover{
				color: $main-color;
			}
		}

		&::after{
			position: absolute;
			left: -14px;
			top: 0;
			content: "|";
		}
	}

	&__text{

	}

	&--article{

		#{$selector}__text{
			margin-bottom: 16px;
		}

		#{$selector}__more{
			font-style: italic;
			float: right;
		}


	}


	&--white{
		color: #fff;

		#{$selector}__title{
			color: #fff;
		}

	}

	&--center{
		text-align: center;

		#{$selector}__title{
			&::before{
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}
		}

	}

	&__facebook{
		font-size: rem(36px);
	}

}
	
