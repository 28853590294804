.eurostatics-list{

	padding-left: 56px;
	padding-right: 56px;
	width: 100%;
	position: relative;
	top: -240px;

	@include media-max($break_lg){
		top: -36px;
		margin-bottom: 80px;
	}

	@include media-max($break_xs){
		padding-left: 0;
		padding-right: 0;
	}
	
	&__item{
		height: 280px;
		width: 280px;
		position: absolute;		
		transform: rotate(45deg);
		overflow: hidden;

		&:nth-child(1){
			right: 467px;
			top: 0px;
		}

		&:nth-child(2){
			right: 262px;
			top: 205px;
		}

		&:nth-child(3){
			right: 57px;
			top: 410px;
		}

		@include media-max($break_lg){
			position: static;
			margin: 116px auto 0;
		}

		@include media-max($break_xs){
			width: 200px;
			height: 200px;
			margin-top: 82px;
		}
	}

	&__wrapper{
		position: absolute;
		top: -68px;
		left: -68px;
		right: -68px;
		bottom: -68px;
		transform: rotate(-45deg);
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: center;
		background-size: cover;
		background-position: 50% 50%;

		&::before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(#000, .4);
		}

	}

	&__titel{
		position: relative;
		//align-self:center;
		width: 100%;
		text-align: center;
		font-size: rem(28px);
		color: #fff;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 8px;

		@include media-max($break_xs){
			font-size: rem(22px);
		}
	}

	&__btn{
		position: relative;
	}
}