.main-nav {
	@include wrapper();
	&__row {
		@include row-flex();
		min-height: 100px;
	}

	&__logo {
		@include col();
		@include size(2);
		@include size-lg(12);
	}

	&__menu {
		@include col();
		@include size(10);
		@include size-lg(12);

		@include media-max($mobile-switch){
			background-color: #E2E2E2;
			margin-left: 0;
			margin-right: 0;
			width: 100% !important;
		}
	}

}