$columns: 12;
$offset: 30px;
$offset_one_side: ($offset / 2);
$atom: (100% / $columns);

$break_lg: 1200px;
$break_md: 992px;
$break_sm: 720px;
$break_xs: 576px;

@mixin reset(){
    body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td{
        margin: 0;
        padding: 0;
    }
    table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    fieldset,img,abbr{
        border: 0;
    }
    address,caption,cite,code,dfn,em,strong,th,var{
        font-style: normal;
        font-weight: normal;
    }
    ul li{
        list-style: none;
    }
    caption,th{
        text-align: left;
    }
    h1,h2,h3,h4,h5,h6{
        font-size: 100%;
        font-weight: normal;
    }
    sup{
        vertical-align: text-top;
    }
    sub{
        vertical-align: text-bottom;
    }
    input,textarea,select{
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
    legend{
        color: #000;
    }
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{
        display: block;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

@mixin wrapper(){
    max-width: 1280px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0 auto;

    @media screen and (max-width: $break_lg){
        padding-left: 30px;
        padding-right: 30px;
    }

    @media screen and (max-width: $break_md){
        padding-left: 15px;
        padding-right: 15px;
    }

    @media screen and (max-width: $break_sm){
        padding-left: 15px;
        padding-right: 15px;
    }

    @media screen and (max-width: $break_xs){
        padding-left: 15px;
        padding-right: 15px;
    }
}
@mixin row-flex(){
    display: flex;
    flex-wrap: wrap;
    margin-left: ($offset_one_side * -1);
    margin-right: ($offset_one_side * -1);
}
@mixin row-float(){
    margin-left: ($offset_one_side * -1);
    margin-right: ($offset_one_side * -1);
    @include clearfix();
}
@mixin col(){
    box-sizing: border-box;
    margin-left: $offset_one_side;
    margin-right: $offset_one_side;
    word-wrap: break-word;
}
@mixin col-float(){
    float: left;
}
@mixin col-padding(){
    padding-left: $offset_one_side;
    padding-right: $offset_one_side;
    word-wrap: break-word;
}
@mixin size($n){
    $value: $atom * $n;
    width: #{calc(#{$value} - #{$offset})};
}
@mixin col-1(){
    @include size(1);
}
@mixin col-2(){
    @include size(2);
}
@mixin col-3(){
    @include size(3);
}
@mixin col-4(){
    @include size(4);
}
@mixin col-5(){
    @include size(5);
}
@mixin col-6(){
    @include size(6);
}
@mixin col-7(){
    @include size(7);
}
@mixin col-8(){
    @include size(8);
}
@mixin col-9(){
    @include size(9);
}
@mixin col-10(){
    @include size(10);
}
@mixin col-11(){
    @include size(11);
}
@mixin col-12(){
    @include size(12);
}
@mixin offset($n){
    $value: $atom * $n;
    margin-left: #{calc(#{$value} + #{$offset_one_side})};
    margin-right: #{calc(#{$value} + #{$offset_one_side})};
}
@mixin offset-left($n){
    $value: $atom * $n;
    margin-left: #{calc(#{$value} + #{$offset_one_side})};
}
@mixin offset-right($n){
    $value: $atom * $n;
    margin-right: #{calc(#{$value} + #{$offset_one_side})};
}
@mixin offset-padding($n){
    $value: $atom * $n;
    padding-left: #{calc(#{$value} + #{$offset_one_side})};
    padding-right: #{calc(#{$value} + #{$offset_one_side})};
}
@mixin offset-left-padding($n){
    $value: $atom * $n;
    padding-left: #{calc(#{$value} + #{$offset_one_side})};
}
@mixin offset-right-padding($n){
    $value: $atom * $n;
    padding-right: #{calc(#{$value} + #{$offset_one_side})};
}
@mixin justify-content($value){
    justify-content: $value;
}
@mixin align-items($value){
    align-items: $value;
}
@mixin align-content($value){
    align-content: $value;
}
@mixin align-self($value){
    align-self: $value;
}
@mixin order($value){
    order: $value;
}
@mixin flex($value){
    flex: $value;
}
@mixin flex-grow($value){
    flex-grow: $value;
}
@mixin flex-shrink($value){
    flex-shrink: $value;
}
@mixin flex-basis($value){
    flex-basis: $value;
}
@mixin flex-direction($value){
    flex-direction: $value;
}
@mixin flex-wrap($value){
    flex-wrap: $value;
}
@mixin flex-flow($value){
    flex-flow: $value;
}
@mixin float($value){
    float: $value;
}
@mixin size-lg($n){
    @media screen and (max-width: $break_lg){
        $value: $atom * $n;
        width: #{calc(#{$value} - #{$offset})};
    }
}
@mixin col-lg-1(){
    @include size-lg(1);
}
@mixin col-lg-2(){
    @include size-lg(2);
}
@mixin col-lg-3(){
    @include size-lg(3);
}
@mixin col-lg-4(){
    @include size-lg(4);
}
@mixin col-lg-5(){
    @include size-lg(5);
}
@mixin col-lg-6(){
    @include size-lg(6);
}
@mixin col-lg-7(){
    @include size-lg(7);
}
@mixin col-lg-8(){
    @include size-lg(8);
}
@mixin col-lg-9(){
    @include size-lg(9);
}
@mixin col-lg-10(){
    @include size-lg(10);
}
@mixin col-lg-11(){
    @include size-lg(11);
}
@mixin col-lg-12(){
    @include size-lg(12);
}
@mixin offset-lg($n){
    @media screen and (max-width: $break_lg){
        $value: $atom * $n;
        margin-left: #{calc(#{$value} + #{$offset_one_side})};
        margin-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-left-lg($n){
    @media screen and (max-width: $break_lg){
        $value: $atom * $n;
        margin-left: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-right-lg($n){
    @media screen and (max-width: $break_lg){
        $value: $atom * $n;
        margin-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-padding-lg($n){
    @media screen and (max-width: $break_lg){
        $value: $atom * $n;
        padding-left: #{calc(#{$value} + #{$offset_one_side})};
        padding-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-left-padding-lg($n){
    @media screen and (max-width: $break_lg){
        $value: $atom * $n;
        padding-left: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-right-padding-lg($n){
    @media screen and (max-width: $break_lg){
        $value: $atom * $n;
        padding-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin justify-content-lg($value){
    @media screen and (max-width: $break_lg){
        justify-content: $value;
    }
}
@mixin align-items-lg($value){
    @media screen and (max-width: $break_lg){
        align-items: $value;
    }
}
@mixin align-content-lg($value){
    @media screen and (max-width: $break_lg){
        align-content: $value;
    }
}
@mixin align-self-lg($value){
    @media screen and (max-width: $break_lg){
        align-self: $value;
    }
}
@mixin order-lg($value){
    @media screen and (max-width: $break_lg){
        order: $value;
    }
}
@mixin flex-lg($value){
    @media screen and (max-width: $break_lg){
        flex: $value;
    }
}
@mixin flex-grow-lg($value){
    @media screen and (max-width: $break_lg){
        flex-grow: $value;
    }
}
@mixin flex-shrink-lg($value){
    @media screen and (max-width: $break_lg){
        flex-shrink: $value;
    }
}
@mixin flex-basis-lg($value){
    @media screen and (max-width: $break_lg){
        flex-basis: $value;
    }
}
@mixin flex-direction-lg($value){
    @media screen and (max-width: $break_lg){
        flex-direction: $value;
    }
}
@mixin flex-wrap-lg($value){
    @media screen and (max-width: $break_lg){
        flex-wrap: $value;
    }
}
@mixin flex-flow-lg($value){
    @media screen and (max-width: $break_lg){
        flex-flow: $value;
    }
}
@mixin float-lg($value){
    @media screen and (max-width: $break_lg){
        float: $value;
    }
}
@mixin lg($name, $value){
    @media screen and (max-width: $break_lg){
        #{$name}: $value;
    }
}
@mixin lg-block(){
    @media screen and (max-width: $break_lg){
        @content;
    }
}
@mixin size-md($n){
    @media screen and (max-width: $break_md){
        $value: $atom * $n;
        width: #{calc(#{$value} - #{$offset})};
    }
}
@mixin col-md-1(){
    @include size-md(1);
}
@mixin col-md-2(){
    @include size-md(2);
}
@mixin col-md-3(){
    @include size-md(3);
}
@mixin col-md-4(){
    @include size-md(4);
}
@mixin col-md-5(){
    @include size-md(5);
}
@mixin col-md-6(){
    @include size-md(6);
}
@mixin col-md-7(){
    @include size-md(7);
}
@mixin col-md-8(){
    @include size-md(8);
}
@mixin col-md-9(){
    @include size-md(9);
}
@mixin col-md-10(){
    @include size-md(10);
}
@mixin col-md-11(){
    @include size-md(11);
}
@mixin col-md-12(){
    @include size-md(12);
}
@mixin offset-md($n){
    @media screen and (max-width: $break_md){
        $value: $atom * $n;
        margin-left: #{calc(#{$value} + #{$offset_one_side})};
        margin-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-left-md($n){
    @media screen and (max-width: $break_md){
        $value: $atom * $n;
        margin-left: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-right-md($n){
    @media screen and (max-width: $break_md){
        $value: $atom * $n;
        margin-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-padding-md($n){
    @media screen and (max-width: $break_md){
        $value: $atom * $n;
        padding-left: #{calc(#{$value} + #{$offset_one_side})};
        padding-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-left-padding-md($n){
    @media screen and (max-width: $break_md){
        $value: $atom * $n;
        padding-left: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-right-padding-md($n){
    @media screen and (max-width: $break_md){
        $value: $atom * $n;
        padding-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin justify-content-md($value){
    @media screen and (max-width: $break_md){
        justify-content: $value;
    }
}
@mixin align-items-md($value){
    @media screen and (max-width: $break_md){
        align-items: $value;
    }
}
@mixin align-content-md($value){
    @media screen and (max-width: $break_md){
        align-content: $value;
    }
}
@mixin align-self-md($value){
    @media screen and (max-width: $break_md){
        align-self: $value;
    }
}
@mixin order-md($value){
    @media screen and (max-width: $break_md){
        order: $value;
    }
}
@mixin flex-md($value){
    @media screen and (max-width: $break_md){
        flex: $value;
    }
}
@mixin flex-grow-md($value){
    @media screen and (max-width: $break_md){
        flex-grow: $value;
    }
}
@mixin flex-shrink-md($value){
    @media screen and (max-width: $break_md){
        flex-shrink: $value;
    }
}
@mixin flex-basis-md($value){
    @media screen and (max-width: $break_md){
        flex-basis: $value;
    }
}
@mixin flex-direction-md($value){
    @media screen and (max-width: $break_md){
        flex-direction: $value;
    }
}
@mixin flex-wrap-md($value){
    @media screen and (max-width: $break_md){
        flex-wrap: $value;
    }
}
@mixin flex-flow-md($value){
    @media screen and (max-width: $break_md){
        flex-flow: $value;
    }
}
@mixin float-md($value){
    @media screen and (max-width: $break_md){
        float: $value;
    }
}
@mixin md($name, $value){
    @media screen and (max-width: $break_md){
        #{$name}: $value;
    }
}
@mixin md-block(){
    @media screen and (max-width: $break_md){
        @content;
    }
}
@mixin size-sm($n){
    @media screen and (max-width: $break_sm){
        $value: $atom * $n;
        width: #{calc(#{$value} - #{$offset})};
    }
}
@mixin col-sm-1(){
    @include size-sm(1);
}
@mixin col-sm-2(){
    @include size-sm(2);
}
@mixin col-sm-3(){
    @include size-sm(3);
}
@mixin col-sm-4(){
    @include size-sm(4);
}
@mixin col-sm-5(){
    @include size-sm(5);
}
@mixin col-sm-6(){
    @include size-sm(6);
}
@mixin col-sm-7(){
    @include size-sm(7);
}
@mixin col-sm-8(){
    @include size-sm(8);
}
@mixin col-sm-9(){
    @include size-sm(9);
}
@mixin col-sm-10(){
    @include size-sm(10);
}
@mixin col-sm-11(){
    @include size-sm(11);
}
@mixin col-sm-12(){
    @include size-sm(12);
}
@mixin offset-sm($n){
    @media screen and (max-width: $break_sm){
        $value: $atom * $n;
        margin-left: #{calc(#{$value} + #{$offset_one_side})};
        margin-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-left-sm($n){
    @media screen and (max-width: $break_sm){
        $value: $atom * $n;
        margin-left: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-right-sm($n){
    @media screen and (max-width: $break_sm){
        $value: $atom * $n;
        margin-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-padding-sm($n){
    @media screen and (max-width: $break_sm){
        $value: $atom * $n;
        padding-left: #{calc(#{$value} + #{$offset_one_side})};
        padding-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-left-padding-sm($n){
    @media screen and (max-width: $break_sm){
        $value: $atom * $n;
        padding-left: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-right-padding-sm($n){
    @media screen and (max-width: $break_sm){
        $value: $atom * $n;
        padding-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin justify-content-sm($value){
    @media screen and (max-width: $break_sm){
        justify-content: $value;
    }
}
@mixin align-items-sm($value){
    @media screen and (max-width: $break_sm){
        align-items: $value;
    }
}
@mixin align-content-sm($value){
    @media screen and (max-width: $break_sm){
        align-content: $value;
    }
}
@mixin align-self-sm($value){
    @media screen and (max-width: $break_sm){
        align-self: $value;
    }
}
@mixin order-sm($value){
    @media screen and (max-width: $break_sm){
        order: $value;
    }
}
@mixin flex-sm($value){
    @media screen and (max-width: $break_sm){
        flex: $value;
    }
}
@mixin flex-grow-sm($value){
    @media screen and (max-width: $break_sm){
        flex-grow: $value;
    }
}
@mixin flex-shrink-sm($value){
    @media screen and (max-width: $break_sm){
        flex-shrink: $value;
    }
}
@mixin flex-basis-sm($value){
    @media screen and (max-width: $break_sm){
        flex-basis: $value;
    }
}
@mixin flex-direction-sm($value){
    @media screen and (max-width: $break_sm){
        flex-direction: $value;
    }
}
@mixin flex-wrap-sm($value){
    @media screen and (max-width: $break_sm){
        flex-wrap: $value;
    }
}
@mixin flex-flow-sm($value){
    @media screen and (max-width: $break_sm){
        flex-flow: $value;
    }
}
@mixin float-sm($value){
    @media screen and (max-width: $break_sm){
        float: $value;
    }
}
@mixin sm($name, $value){
    @media screen and (max-width: $break_sm){
        #{$name}: $value;
    }
}
@mixin sm-block(){
    @media screen and (max-width: $break_sm){
        @content;
    }
}
@mixin size-xs($n){
    @media screen and (max-width: $break_xs){
        $value: $atom * $n;
        width: #{calc(#{$value} - #{$offset})};
    }
}
@mixin col-xs-1(){
    @include size-xs(1);
}
@mixin col-xs-2(){
    @include size-xs(2);
}
@mixin col-xs-3(){
    @include size-xs(3);
}
@mixin col-xs-4(){
    @include size-xs(4);
}
@mixin col-xs-5(){
    @include size-xs(5);
}
@mixin col-xs-6(){
    @include size-xs(6);
}
@mixin col-xs-7(){
    @include size-xs(7);
}
@mixin col-xs-8(){
    @include size-xs(8);
}
@mixin col-xs-9(){
    @include size-xs(9);
}
@mixin col-xs-10(){
    @include size-xs(10);
}
@mixin col-xs-11(){
    @include size-xs(11);
}
@mixin col-xs-12(){
    @include size-xs(12);
}
@mixin offset-xs($n){
    @media screen and (max-width: $break_xs){
        $value: $atom * $n;
        margin-left: #{calc(#{$value} + #{$offset_one_side})};
        margin-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-left-xs($n){
    @media screen and (max-width: $break_xs){
        $value: $atom * $n;
        margin-left: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-right-xs($n){
    @media screen and (max-width: $break_xs){
        $value: $atom * $n;
        margin-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-padding-xs($n){
    @media screen and (max-width: $break_xs){
        $value: $atom * $n;
        padding-left: #{calc(#{$value} + #{$offset_one_side})};
        padding-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-left-padding-xs($n){
    @media screen and (max-width: $break_xs){
        $value: $atom * $n;
        padding-left: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin offset-right-padding-xs($n){
    @media screen and (max-width: $break_xs){
        $value: $atom * $n;
        padding-right: #{calc(#{$value} + #{$offset_one_side})};
    }
}
@mixin justify-content-xs($value){
    @media screen and (max-width: $break_xs){
        justify-content: $value;
    }
}
@mixin align-items-xs($value){
    @media screen and (max-width: $break_xs){
        align-items: $value;
    }
}
@mixin align-content-xs($value){
    @media screen and (max-width: $break_xs){
        align-content: $value;
    }
}
@mixin align-self-xs($value){
    @media screen and (max-width: $break_xs){
        align-self: $value;
    }
}
@mixin order-xs($value){
    @media screen and (max-width: $break_xs){
        order: $value;
    }
}
@mixin flex-xs($value){
    @media screen and (max-width: $break_xs){
        flex: $value;
    }
}
@mixin flex-grow-xs($value){
    @media screen and (max-width: $break_xs){
        flex-grow: $value;
    }
}
@mixin flex-shrink-xs($value){
    @media screen and (max-width: $break_xs){
        flex-shrink: $value;
    }
}
@mixin flex-basis-xs($value){
    @media screen and (max-width: $break_xs){
        flex-basis: $value;
    }
}
@mixin flex-direction-xs($value){
    @media screen and (max-width: $break_xs){
        flex-direction: $value;
    }
}
@mixin flex-wrap-xs($value){
    @media screen and (max-width: $break_xs){
        flex-wrap: $value;
    }
}
@mixin flex-flow-xs($value){
    @media screen and (max-width: $break_xs){
        flex-flow: $value;
    }
}
@mixin float-xs($value){
    @media screen and (max-width: $break_xs){
        float: $value;
    }
}
@mixin xs($name, $value){
    @media screen and (max-width: $break_xs){
        #{$name}: $value;
    }
}
@mixin xs-block(){
    @media screen and (max-width: $break_xs){
        @content;
    }
}
@mixin debug($background, $outline){
    height: 100vh;
    position: fixed;
    width: 100%;
    
    > div{
        height: inherit;
        @include wrapper();
        
        > div{
            height: inherit;
            @include row-flex();
            
            > div{
                @include col();
                @include size(1);
                height: inherit;
                outline: $outline;
                background: $background;
            }
        }
    }
}
@mixin clearfix(){
    &::after{
        display: block;
        content: "";
        clear: both;
    }
}
