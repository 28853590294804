@import 'reboot';
@import 'fonts';
@import 'typography';

.main-wrap{
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.top{
	flex-grow: 0;
}

.bottom{
	flex-grow: 0;
}

.main{
	flex-grow: 1;
}