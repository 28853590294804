// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

// =============================================================================
// List
// =============================================================================


@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}

@mixin list-inline {
  @include list-unstyled;
  //margin-left: -5px;

  > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}

// =============================================================================
// Breakpoint
// =============================================================================
@mixin media-min($size) {
	@media only screen and (min-width: $size) {
		@content;
	}
}

@mixin media-max($size) {
	@media only screen and (max-width : $size){
		@content;
	}
}

// =============================================================================
// Img
// =============================================================================

@mixin responsive-img() {
	display: block;
  max-width: 100%;
  height: auto;
}

// =============================================================================
// Clearfix
// =============================================================================

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}