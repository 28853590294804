.top-line {
	@include wrapper();
	&__row {
		@include row-flex();
		align-items: center;
		min-height: 46px;
		color: #fff;
		font-size: rem(13px);
	}

	&__lang {
		@include col();
		@include size(1);
		@include size-md(4);
		@include size-xs(12);
	}

	&__contact {
		@include col();
		@include size(7);
		@include size-md(12);
		@include order-md(2);
	}

	&__partners {
		@include col();
		@include size(4);
		@include size-md(8);
		@include size-xs(12);
		@include order-md(1);	
	}
}