.main-contact{
	@include wrapper();
	
	&__row{
		@include row-flex();
	}

	&__title{
		@extend %section-title;
		@extend %title-vertical;
	}

	&__left{
		@include col();
		@include size(4);
		@include size-lg(5);
		@include size-md(12);
		@include offset-left(1);
		@include offset-left-lg(0);

		@include media-max($break_md){
			margin-bottom: 40px;
		}
	}

	&__right{
		@include col();
		@include size(7);
		@include size-lg(7);
		@include size-md(12);
	}

	&__info{
		margin-bottom: 41px;
	}

}