*, *::after, *::before {
    box-sizing: border-box;
}

html, body{
	height: 100%;
	font-size: $main-font-size;
	@include media-max($break_xs) {
		font-size: 14px;
	}
}

body{
	font-family: $main-font-family;	
	min-width: 320px;
	position: relative;
	line-height: 1.75;
	overflow-x: hidden;
	color: $text-color;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

[role="button"] {
  cursor: pointer;
}