.partners{
	text-align: center;
	color: $text-color;
	background-color: $accent-color;
	margin-left: 0 30px;
	
	@include media-max($break_md){
		background-color: transparent;
		color: #fff;
		text-align: right;
	}

	@include media-max($break_xs){
		text-align: center;
		padding: 4px 0;
		background-color: $accent-color;
		color: $text-color;
		a{
			color: $text-color;
			&:hover{
				color: $main-color;
			}
		}
	}

	&__item{
		line-height: rem(46px);
		&:hover{
			color: #333;
		}

		@include media-max($break_md){
			color: #fff;
			&:hover{
				color: $accent-color;
			}
		}

	}	
}