%dropdown {
	cursor: pointer;
}

%dropdown__selected {
	position: relative;
	&::after{
		position: absolute;
		content: '';
		font-family: FontAwesome;
		margin-left: 8px;
	}
}

%dropdown__menu {
	color: #333;
	@include list-unstyled();
	position: absolute;
	max-height: 0;
	overflow: hidden;
	//display: none;
	transition: max-height .9s;
	z-index: 10000;
}

/* .dropdown-show{	
	%dropdown__menu {
		//display: block;
		max-height: 1000px !important;
		}
} */