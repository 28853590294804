.events{
	@include wrapper();
	
	&__row{
		@include row-flex();
		margin-bottom: 60px;
	}

	&__title{
		@extend %section-title;
	}

	&__item{
		@include col();
		@include size(5);
		@include size-md(12);
		@include offset-left(1);
		@include offset-left-md(0);

		@include media-max($break_md){
			margin-bottom: 40px;
		}
	}

	&__btn{
		text-align: center;
	}

}