.contact {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	@include media-max($break_xs){
		padding: 8px 0;
	}
	
	&__item {
		display: flex;
		align-items:center;
	}

	&__icon {
		margin-right: 8px;
		font-size: rem(16px);
		color: $accent-color;
	}

	&__text {		
			
		a{
			color: #fff;
			text-decoration: none;
		}

	}
	
}