.main-slider{
	margin-top: -$menu-height;

	@include media-max($break_md){
			margin-top: 0;
	}

	&__item{
		height: 640px;
		background-size: cover;

		position: relative;
		display: flex;
		flex-flow: column wrap;		
		align-items: end;
		padding-bottom: 20px;

		&::before{
			position: absolute;
			content: "";
			background-color: rgba($main-color, .45);
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}

		@include media-max($break_sm){
			height: 480px;
		}

		@include media-max($break_xs){
			height: 320px;
		}

	}

	&__title{
		padding: 0 56px;
		@include wrapper();
		position: relative;
		align-self: center;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: rem(48px);
		font-family: $accent-font-family;
		line-height: rem(52px);
		margin-top: auto;
		margin-bottom: auto;

		@include media-max($break_md){
			font-size: rem(36px);
		}

		@include media-max($break_sm){
			font-size: rem(28px);
			line-height: rem(36px);
		}

		@include media-max($break_xs){
			font-size: rem(22px);
			line-height: rem(28px);
			padding: 0 15px;
		}
	}

	&__descr{
		position: relative;
		padding: 0 56px;
		text-align: right;
		width: 100%;
		color: $accent-color;
		font-size: rem(18px);
		font-style: italic;
		line-height: rem(22px);
		align-self: center;

		@include media-max($break_xs){
			font-size: rem(16px);
			line-height: rem(20px);
			padding: 0 15px;
		}

	}


 	.owl-prev, .owl-next{
		color: #fff;
		font-size: rem(48px);
		border: 1px solid #fff;
		height: 50px;
		width: 50px;
		position: absolute;
		top: 50%;
		transition: color .2s, border-color .2s;

		@include media-max($break_xs){
			display: none;
		}

		.fa{
			display: block;
			text-align: center;
			line-height: 44px;
		}
		&:hover{
			border-color: $accent-color;
			color: $accent-color;
		}
	}

	.owl-prev{
		left: 0;
		padding-right: 4px;
	}

	.owl-next{
		right: 1px;
		padding-left: 4px;
	}

	.owl-pagination{
		position: absolute;
		bottom: 6px;
		left: 100px;

		@include media-max($break_xs){
			left: 0;
			padding-left: 15px;
		}

	}

	.owl-page{
		height: 16px;
		width: 16px;
		border-radius: 100%;
		background-color: #fff;
		display: inline-block;
		margin-right: 6px;

		&.active{
			background-color: $accent-color;
		}

		@include media-max($break_xs){
			height: 10px;
			width: 10px;			
		}
	}

}