/* @mixin nav--color($background-color: $nav-background-color, $color: $nav-color) {
  background-color: $background-color;
  color: $color;
}

@mixin nav__item--color($background-color: $nav-item-hover-background-color, $hover-color: $nav-item-hover-color) {
  &:not(:disabled) {
    &:hover {
      background-color: $background-color;
      color: $hover-color;
    }

    &:focus {
      box-shadow: $box-shadow-focus;
    }

    &:active {
      background-color: darken($background-color, $color-tint);
      color: $hover-color;
    }
  }
}

@mixin nav__item--active($background-color: $nav-item-active-background-color, $hover-color: $nav-item-active-color) {
  background-color: $background-color;
  color: $hover-color;
}
 */


 // Burger

.menu-button {
  position: absolute;
  right: 20px;
  top: 32px;
  display: inline-block;
  padding: .5em;
  display: none;
  @include media-max($mobile-switch){
    display: block;
  }
}

.burger-icon {
  @include burger(25px, 3px, 5px, #111);  
}

.menu-button.is-active .burger-icon {
  @include burger-to-cross;
}


@mixin nav-wrap($bg-color: $accent-color, $height: $menu-height){ 
  background-color: $bg-color;
  height: $height;
  position: relative;
  &__dropdown{
    
    overflow: hidden;
    visibility: hidden;
    height: 0;
    opacity: 0;
    position: absolute;
    transition: all .5s;
    background-color: $bg-color;
    padding: 0;
    top: 100%;
    left: 0;
    width: 100%;
  }
}

@mixin nav($bg-color: $accent-color, $height: $menu-height){
  @include list-unstyled();
  display: flex;
  margin: 0;
  @include media-max($mobile-switch){
    display: none;
    &.is-open{
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: darken($accent-color, 10%);
    }
  }
}

@mixin nav-item($bg-color: $accent-color, $height: $menu-height){
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0;
  $dropdown: &;

  a{
    display: block;
    width: 100%;
    color: darken($bg-color, 40%);
    text-decoration: none;
    line-height: $height;
    padding: 0 30px;
    outline:none;
    transition: background .2s;
    &:hover{
      background-color: lighten($bg-color, 10%);
    }
  }  
}



// Example
/* .nav-test{
  @include nav-wrap();
  &__menu{
    @include nav();
  }
  &__item{
    @include nav-item();
  }
} */