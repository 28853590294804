.eurostatics-media{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 100px;

	&__item{
		width: 400px;
		height: 400px;
		transform: rotate(45deg);
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0 82px;
		transition: opacity .2s;

		&:hover{
			opacity: 0.6;
		}

		@include media-max($break_lg){
			width: 300px;
			height: 300px;
		}

		@include media-max($break_md){
			margin-bottom: 124px;
			&:last-child{
				margin-bottom: 0;
			}
		}

		@include media-max($break_xs){
			width: 200px;
			height: 200px;
			margin-left: 40px;
			margin-right: 40px;
			margin-bottom: 82px;

			&:last-child{
				margin-bottom: 0;
			}
		}

	}

	&__wrap{
		transform: rotate(-45deg);
		position: absolute;
		top: -96px;
		bottom: -96px;
		left: -96px;
		right: -96px;
		background-size: cover;
		background-position: center;
		color: #fff;
		display: flex;
		align-items:center;
		justify-content: center;
		text-transform: uppercase;
		font-size: rem(40px);
		font-weight: bold;
		&::before{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(#000, .4);
		}
	}

	&__name{
		position: relative;

		@include media-max($break_xs){
			font-size: rem(30px);
		}
	}

}