.command{
	$selector: &;

	@include wrapper();
	position: relative;

	&__row{
		//@include row-flex();
	}

	&__title{
		@extend %section-title;
		color: #fff;
	}

	.owl-buttons{
		display: flex;
		justify-content: center;
		margin-top: 46px;
		opacity: .6;
		.fa{
			font-size: rem(30px);
			color: #fff;
		}
	}

	.owl-prev, .owl-next{
		position: relative;
		padding: 7px 19px;
		
		&::before{
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border: 1px solid #fff;
			transform: rotate(45deg);
		}

		&:hover{
			.fa{
				color: $accent-color;
			}
			&::before{
				border-color: $accent-color;
			}
		}
	}

	.owl-prev{
		margin-right: 14px;
	}

	.owl-next{
		margin-left: 14px;
	}

	&--internal{

		#{$selector}__title{
			color: $main-color;
		}

			.owl-prev, .owl-next{

				&::before{
					border-color: $text-color;
				}

				&:hover{
					.fa{
						color: $main-color;
					}
					&::before{
						border-color: $main-color;
					}
				}
			}

			.owl-buttons{
				.fa{
					color: $text-color;
				}
			}

	}

}