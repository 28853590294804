.c-pagination {
  display: block;
  width: $pagination-width;
  padding: $pagination-padding;
  font-size: $pagination-font-size;
  text-align: center;
}

.c-pagination__controls {
  display: inline-block;
  text-align: center;
  padding-left: 0;
}

.c-pagination__controls--backward {
  float: left;
  text-align: left;
}

.c-pagination__controls--forward {
  float: right;
  text-align: right;
}

.c-pagination__control,
.c-pagination__page {
  @include button;
  @include button-ghost($pagination-control-color);
  min-width: 2.4em;
  border-radius: $pagination-control-border-radius;
  padding: 0;
  margin-right: 4px;
}

.c-pagination__page--current {
  background-color: $pagination-page-current-background-color;
  color: $pagination-page-current-color;
  padding: 5px 10px;
}

.c-pagination__ellipsis {
  padding: $pagination-ellipsis-padding;
}

.c-pagination__link{
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  color: #827d7d;
  &:hover{
    color: #fff;
    text-decoration: none;
  }
}