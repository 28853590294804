.command-list{
	$selector: &;

  margin-top: 60px;

  &__item-overlay{
  	width: 260px;
  	margin: 0 auto;
  	&:first-child{
  		//margin-left: 55px;
  		margin-top: 55px;
  	}

		@include media-max($break_xs){
			width: 200px;
		}
  }

	&__item{
		
		height: 260px;
		border: 20px solid #fff;
		transform: rotate(45deg);
		overflow: hidden;
		position: relative;

		@include media-max($break_xs){
			height: 200px;
		}
	}

	&__wrap{
		transform: rotate(-45deg);
		position: absolute;
		top: -50px;
		bottom: -50px;
		left: -50px;
		right: -50px;
		background-position: center;

		img{
			@include responsive-img();
		}
	}

	&__content{
		margin-top: 80px;
		margin-left: -55px;
		margin-right: -55px;
	}
}