.main-menu {
	@include nav-wrap(transparent);

	@include media-max($mobile-switch){
		height: 50px;
		.menu-button{
			top: 10px;
		}
	}

	&__wrap{
		@include nav(transparent);
		justify-content: end;
	}



	&__item{
		@include nav-item();		

		a{
			text-transform: uppercase;
			font-size: rem(17px);
			color: #000;
			padding: 0 26px;

			@include media-max($mobile-switch){
  			line-height: 50px;
  		}

		}

		&--dropdown{
			>a {
				@extend %dropdown__selected;
					&::after{
					color: #333;
					right: 6px;
					top: 0;
				}
				&::after{
					color: #333;
				}
			}

			.main-menu__item--active a{
				color: #fff;

				&::after{
					color: #fff !important;
				}							
			}
		}		


		&--active > a, & > a:hover, &.dropdown-show > a{
    	background-color: $main-color;
			color: #fff;

			&::after{
				color: #fff;
			}
  	}


		&.dropdown-show > a{
			background-color: lighten($main-color, 60%);
		}


	}


	&__dropdown{
		min-width:280px;
		background-color: $accent-color;
		position: absolute;
		@include media-max($mobile-switch){
  			position: static;
  		}
		li{
			margin: 0;
		}
		a{
			line-height: 22px !important;
			color: #111;
			text-transform: none;
			border-bottom: 1px solid rgba(255,255,255, .3);
			padding: 10px 30px;
		}
	}

}


.dropdown-show {
	.main-menu__dropdown {
		opacity: 1;
		transition: all .5s;
		visibility: visible;
		height: auto;
	}

	&::after{
		color: #fff;
	}
}