// String Replace

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)

@function em($pxval, $base: $main-font-size) {
	@return ($pxval/$base)+0em;
}


// Convert pixels to ems

@function rem($pxval) {
  @return ($pxval/$main-font-size)*1rem;
}