.article{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 50px;
	&__img{
		flex-basis: 32%;
		flex-grow: 1;		
		margin: 8px 24px 8px 0;

		@include media-max($break_sm){
			flex-basis: 100%;
		}

		img{
			@include responsive-img();
		}
	}

	&__content{
		flex-basis: 32%;
		flex-grow: 1;

		@include media-max($break_sm){
			flex-basis: 100%;
		}
	}	

}