.page {

	$selector: &;

	&__top-line{		
		background-color: $main-color;
	}

	&__main-nav{
		background-color: rgba(#fff, .2);
		z-index: 2;
		position: relative;
	}

	&__eurostatics{
		@extend %section;		

		&::before{
			top: 0;
			left: 0;
			right: calc(50% + 300px);
			bottom: 159px;
			position: absolute;
			content: "";
			background: url("../img/content/eurostatics/bg.png") no-repeat 100% 100%;
			
			@include media-max($break_md){
				background: none;
			}
		}
	}

	&__news{
		@extend %section;
	}

	&__events{
		@extend %section;
		background-color: #f2f2f2;
	}

	&__command{
		@extend %section;
		background: url("../img/content/command/bg.jpg") no-repeat;
		background-size: cover;
		position: relative;

		&::before{
			position: absolute;
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba($main-color, .7);
		}

		&--gray{
			background: #f2f2f2;
			&::before{
				display: none;
			}
		}

	}

	&__contact{
		@extend %section;
	}

	&__main-footer{
		background-color: $main-color;
		color: #fff;
		padding: 10px 0;
	}



	&--no-home{
		#{$selector}__main-nav{
			background-color: #fff;
			box-shadow: 0 0 4px -2px #000;
		}
	}

	&__about-us{
		@extend %page-wrap;
	}

	&__article-list{
		@extend %page-wrap;
	}

	&__section{
		@extend %page-wrap;
	}

	&__title{
		@extend %section-title;
	}

	&__main{
		@include wrapper();
	}


}