//
/// Screen Sizes
//
$screen-limit-xsmall: 20em !default;
$screen-limit-small: 30em !default;
$screen-limit-medium: 48em !default;
$screen-limit-large: 64em !default;
$screen-limit-xlarge: 78em !default;
$screen-limit-super: 116em !default;

$screen-adjustment: .01 !default;

$screen-limits: (
  xsmall: $screen-limit-xsmall,
  small: $screen-limit-small,
  medium: $screen-limit-medium,
  large: $screen-limit-large,
  xlarge: $screen-limit-xlarge,
  super: $screen-limit-super
);

//
// Spacing
//
$spacing-super: 3em !default;
$spacing-xlarge: 2em !default;
$spacing-large: 1.5em !default;
$spacing-medium: 1em !default;
$spacing-small: .5em !default;
$spacing-xsmall: .25em !default;
$spacing-tiny: .125em !default;

//
// Colors
//
$color-tint: 9 !default;
$color-tint-dark: $color-tint !default;
$color-tint-darker: $color-tint * 2 !default;
$color-tint-light: $color-tint !default;
$color-tint-lighter: $color-tint * 2 !default;

$color-black: #111 !default;
$color-grey: #B0BEC5 !default;
$color-white: #FFF !default;
$color-beige: #F2F2EA !default;
$color-red: #F44336 !default;
$color-pink: #E91E63 !default;
$color-purple: #9C27B0 !default;
$color-blue: #2196F3 !default;
$color-green: #4CAF50 !default;
$color-cyan: #00BCD4 !default;
$color-yellow: #FFEB3B !default;
$color-orange: #FF9800 !default;
$color-brown: #795548 !default;

$color-brand: #2C3E50 !default;
$color-info: $color-blue !default;
$color-warning: $color-orange !default;
$color-success: $color-green !default;
$color-error: $color-red !default;

$color-default: darken($color-grey, $color-tint-dark) !default;
$color-disabled: darken($color-grey, $color-tint-dark) !default;
$color-quiet: darken($color-grey, $color-tint-dark) !default;

$colors: (
  black: $color-black,
  grey: $color-grey,
  white: $color-white,
  beige: $color-beige,
  red: $color-red,
  pink: $color-pink,
  purple: $color-purple,
  blue: $color-blue,
  green: $color-green,
  cyan: $color-cyan,
  yellow: $color-yellow,
  orange: $color-orange,
  brown: $color-brown,
  brand: $color-brand,
  info: $color-info,
  warning: $color-warning,
  success: $color-success,
  error: $color-error,
  default: $color-default,
  disabled: $color-disabled,
  quiet: $color-quiet,
  grey-dark: darken($color-grey, $color-tint-dark),
  red-dark: darken($color-red, $color-tint-dark),
  pink-dark: darken($color-pink, $color-tint-dark),
  purple-dark: darken($color-purple, $color-tint-dark),
  blue-dark: darken($color-blue, $color-tint-dark),
  green-dark: darken($color-green, $color-tint-dark),
  cyan-dark: darken($color-cyan, $color-tint-dark),
  yellow-dark: darken($color-yellow, $color-tint-dark),
  orange-dark: darken($color-orange, $color-tint-dark),
  brown-dark: darken($color-brown, $color-tint-dark),
  grey-darker: darken($color-grey, $color-tint-darker),
  red-darker: darken($color-red, $color-tint-darker),
  grey-light: lighten($color-grey, $color-tint-light),
  red-light: lighten($color-red, $color-tint-light),
  pink-light: lighten($color-pink, $color-tint-light),
  purple-light: lighten($color-purple, $color-tint-light),
  blue-light: lighten($color-blue, $color-tint-light),
  green-light: lighten($color-green, $color-tint-light),
  cyan-light: lighten($color-cyan, $color-tint-light),
  yellow-light: lighten($color-yellow, $color-tint-light),
  orange-light: lighten($color-orange, $color-tint-light),
  brown-light: lighten($color-brown, $color-tint-light),
  grey-lighter: lighten($color-grey, $color-tint-lighter),
  red-lighter: lighten($color-red, $color-tint-lighter),
  pink-lighter: lighten($color-pink, $color-tint-lighter),
  purple-lighter: lighten($color-purple, $color-tint-lighter),
  blue-lighter: lighten($color-blue, $color-tint-lighter),
  green-lighter: lighten($color-green, $color-tint-lighter),
  cyan-lighter: lighten($color-cyan, $color-tint-lighter),
  yellow-lighter: lighten($color-yellow, $color-tint-lighter),
  orange-lighter: lighten($color-orange, $color-tint-lighter),
  brown-lighter: lighten($color-brown, $color-tint-lighter),
  pink-darker: darken($color-pink, $color-tint-darker),
  purple-darker: darken($color-purple, $color-tint-darker),
  blue-darker: darken($color-blue, $color-tint-darker),
  green-darker: darken($color-green, $color-tint-darker),
  cyan-darker: darken($color-cyan, $color-tint-darker),
  yellow-darker: darken($color-yellow, $color-tint-darker),
  orange-darker: darken($color-orange, $color-tint-darker),
  brown-darker: darken($color-brown, $color-tint-darker),
  brand-dark: darken($color-brand, $color-tint-dark),
  brand-darker: darken($color-brand, $color-tint-darker),
  brand-light: lighten($color-brand, $color-tint-light),
  brand-lighter: lighten($color-brand, $color-tint-lighter),
  info-dark: darken($color-info, $color-tint-dark),
  info-darker: darken($color-info, $color-tint-darker),
  info-light: lighten($color-info, $color-tint-light),
  info-lighter: lighten($color-info, $color-tint-lighter),
  warning-dark: darken($color-warning, $color-tint-dark),
  warning-darker: darken($color-warning, $color-tint-darker),
  warning-light: lighten($color-warning, $color-tint-light),
  warning-lighter: lighten($color-warning, $color-tint-lighter),
  success-dark: darken($color-success, $color-tint-dark),
  success-darker: darken($color-success, $color-tint-darker),
  success-light: lighten($color-success, $color-tint-light),
  success-lighter: lighten($color-success, $color-tint-lighter),
  error-dark: darken($color-error, $color-tint-dark),
  error-darker: darken($color-error, $color-tint-darker),
  error-light: lighten($color-error, $color-tint-light),
  error-lighter: lighten($color-error, $color-tint-lighter)
);

//
// Borders
//
$border-width: 1px !default;
$border-style: solid !default;
$border-color: map-get($colors, default) !default;

//
// Border radius
//
$border-radius: 4px !default;
$border-radius-rounded: 30em !default;

//
// Box shadows
//
$box-shadow: 0 0 1px rgba(map-get($colors, black), .6) !default;
$box-shadow-high: 0 0 1px rgba(map-get($colors, black), .6), 0 5px 10px -3px rgba(map-get($colors, black), .4) !default;
$box-shadow-higher: 0 0 1px rgba(map-get($colors, black), .6), 0 10px 25px -4px rgba(map-get($colors, black), .4) !default;
$box-shadow-highest: 0 0 1px rgba(map-get($colors, black), .6), 0 20px 55px -8px rgba(map-get($colors, black), .4) !default;

//
// Focus styles
//
$border-color-focus: map-get($colors, blue) !default;
$box-shadow-focus: inset 0 0 0 2px lighten($border-color-focus, $color-tint) !default;

//
// Z Indexes
//
$z-over-content: 100 !default;
$z-over-control: 200 !default;
$z-over-page: 300 !default;
$z-over-screen: 400 !default;
$z-over-everything: 500 !default;

//
// Typography
//
$text-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !default;
$text-font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif !default;
$text-font-family-mono: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace !default;
$text-font-size-super: 2.5em !default;
$text-font-size-xlarge: 2em !default;
$text-font-size-large: 1.5em !default;
$text-font-size-medium: 1em !default;
$text-font-size-small: .85em !default;
$text-font-size-xsmall: .7em !default;
$text-font-weight: normal !default;
$text-font-weight-heavy: bold !default;
$text-line-height: 1.55 !default;
$text-color: map-get($colors, black) !default;
$highlight-padding: $spacing-xsmall $spacing-xsmall $spacing-tiny !default;
$highlight-margin: 0 -$spacing-tiny !default;
$highlight-color: map-get($colors, black) !default;
$highlight-background-color: map-get($colors, yellow) !default;
$help-border-bottom: $border-width dashed map-get($colors, grey-dark) !default;
$paragraph-padding: $spacing-small 0 !default;
$paragraph-margin: 0 !default;
$paragraph-font-style: normal !default;
$code-color: $text-color !default;
$code-background-color: map-get($colors, grey-lighter) !default;
$code-font-family: $text-font-family-mono !default;
$code-font-weight: $text-font-weight !default;
$code-multiline-padding: $spacing-small $spacing-medium !default;
$code-multiline-border-radius: $border-radius !default;
$keyboard-color: map-get($colors, white) !default;
$keyboard-background-color: map-get($colors, grey-dark) !default;
$keyboard-border-width: 2px !default;
$keyboard-border-style: $border-style !default;
$keyboard-border-color: map-get($colors, grey-darker) !default;
$keyboard-border: $keyboard-border-width $keyboard-border-style $keyboard-border-color !default;
$keyboard-border-radius: $border-radius !default;
$quotation-padding: $spacing-medium $spacing-large !default;
$quotation-margin: 0 !default;
$quotation-color: map-get($colors, quiet) !default;
$quotation-font-size: $text-font-size-large !default;
$quotation-font-family: $text-font-family-serif !default;
$quotation-border-width: 5px !default;
$quotation-border-style: $border-style !default;
$quotation-border-color: map-get($colors, default) !default;
$quotation-brand-border-color: map-get($colors, brand) !default;
$quotation-info-border-color: map-get($colors, info) !default;
$quotation-warning-border-color: map-get($colors, warning) !default;
$quotation-success-border-color: map-get($colors, success) !default;
$quotation-error-border-color: map-get($colors, error) !default;
$quotation-footer-color: map-get($colors, quiet) !default;
$quotation-footer-font-style: italic !default;

//
// Addresses
//
$address-font-style: normal !default;
$address-heading-font-weight: $text-font-weight-heavy !default;

//
// Alerts
//
$alerts-width: 250px !default;
$alerts-background-color: transparent !default;
$alerts-margin: $spacing-medium !default;
$alerts-z-index: $z-over-page !default;
$alert-margin: 0 0 $spacing-medium 0 !default;
$alert-padding: $spacing-medium $spacing-super $spacing-medium $spacing-medium !default;
$alert-background-color: map-get($colors, default) !default;
$alert-brand-background-color: map-get($colors, brand) !default;
$alert-info-background-color: map-get($colors, info) !default;
$alert-warning-background-color: map-get($colors, warning) !default;
$alert-error-background-color: map-get($colors, error) !default;
$alert-success-background-color: map-get($colors, success) !default;
$alert-color: map-get($colors, white) !default;
$alert-border-radius: $border-radius !default;

//
// Avatar
//
$avatar-margin: 0 !default;
$avatar-background-color: $color-brand !default;
$avatar-color: $color-white !default;
$avatar-img-border-radius: $border-radius-rounded !default;
$avatar-inner-img-size: 50% !default;
$avatar-xsmall-size: 1em !default;
$avatar-small-size: 2em !default;
$avatar-medium-size: 3em !default;
$avatar-large-size: 4em !default;
$avatar-xlarge-size: 5em !default;
$avatar-super-size: 6em !default;

//
// Badges
//
$badge-padding: $spacing-xsmall $spacing-small !default;
$badge-margin: 0 !default;
$badge-background-color: map-get($colors, default) !default;
$badge-color: map-get($colors, white) !default;
$badge-line-height: 1.2;
$badge-brand-background-color: map-get($colors, brand) !default;
$badge-brand-color: map-get($colors, white) !default;
$badge-info-background-color: map-get($colors, info) !default;
$badge-info-color: map-get($colors, white) !default;
$badge-warning-background-color: map-get($colors, warning) !default;
$badge-warning-color: map-get($colors, white) !default;
$badge-success-background-color: map-get($colors, success) !default;
$badge-success-color: map-get($colors, white) !default;
$badge-error-background-color: map-get($colors, error) !default;
$badge-error-color: map-get($colors, white) !default;
$badge-font-size: .8em !default;
$badge-font-weight: $text-font-weight-heavy !default;
$badge-border-width: $border-width !default;
$badge-border-style: $border-style !default;
$badge-border-color: transparent !default;
$badge-border: $badge-border-width $badge-border-style $badge-border-color !default;
$badge-border-radius: $border-radius !default;
$badge-border-radius-rounded: $border-radius-rounded !default;
$badge-ghost-background-color: transparent !default;
$badge-ghost-brand-background-color: transparent !default;
$badge-ghost-info-background-color: transparent !default;
$badge-ghost-warning-background-color: transparent !default;
$badge-ghost-success-background-color: transparent !default;
$badge-ghost-error-background-color: transparent !default;
$badge-ghost-color: map-get($colors, default) !default;
$badge-ghost-brand-color: map-get($colors, brand) !default;
$badge-ghost-info-color: map-get($colors, info) !default;
$badge-ghost-warning-color: map-get($colors, warning) !default;
$badge-ghost-success-color: map-get($colors, success) !default;
$badge-ghost-error-color: map-get($colors, error) !default;

//
// Breadcrumbs
//
$breadcrumb-margin: 0 !default;
$breadcrumb-padding: 0 !default;
$breadcrumb-crumb-padding: 0 !default;
$breadcrumb-crumb-separator-content: "/" !default;
$breadcrumb-crumb-separator-font-size: $text-font-size-small !default;
$breadcrumb-crumb-separator-padding: 0 $spacing-small !default;
$breadcrumb-crumb-separator-color: map-get($colors, default) !default;

//
// Bubbles
//
$bubble-padding: $spacing-medium !default;
$bubble-background-color: map-get($colors, black) !default;
$bubble-color: map-get($colors, white) !default;
$bubble-text-align: center !default;
$bubble-border-radius: $border-radius !default;
$bubble-arrow-width: 10px !default;

//
// Buttons
//
$button-padding: $spacing-small !default;
$button-margin: 0 !default;
$button-color: map-get($colors, white) !default;
$button-background-color: map-get($colors, default) !default;
$button-brand-color: map-get($colors, white) !default;
$button-brand-background-color: map-get($colors, brand) !default;
$button-info-color: map-get($colors, white) !default;
$button-info-background-color: map-get($colors, info) !default;
$button-warning-color: map-get($colors, white) !default;
$button-warning-background-color: map-get($colors, warning) !default;
$button-success-color: map-get($colors, white) !default;
$button-success-background-color: map-get($colors, success) !default;
$button-error-color: map-get($colors, white) !default;
$button-error-background-color: map-get($colors, error) !default;
$button-disabled-opacity: .5 !default;
$button-font-family: inherit !default;
$button-font-size: 1em !default;
$button-line-height: normal !default;
$button-text-align: center !default;
$button-white-space: nowrap !default;
$button-border-width: $border-width !default;
$button-border-style: $border-style !default;
$button-border-color: transparent !default;
$button-border: $button-border-width $button-border-style $button-border-color !default;
$button-border-radius: $border-radius !default;
$button-focus-border-color: $border-color-focus !default;
$button-focus-box-shadow: $box-shadow-focus !default;
$button-ghost-color: $border-color !default;
$button-ghost-hover-color: map-get($colors, white) !default;
$button-ghost-brand-color: map-get($colors, brand) !default;
$button-ghost-brand-hover-color: map-get($colors, white) !default;
$button-ghost-info-color: map-get($colors, info) !default;
$button-ghost-info-hover-color: map-get($colors, white) !default;
$button-ghost-warning-color: map-get($colors, warning) !default;
$button-ghost-warning-hover-color: map-get($colors, white) !default;
$button-ghost-success-color: map-get($colors, success) !default;
$button-ghost-success-hover-color: map-get($colors, white) !default;
$button-ghost-error-color: map-get($colors, error) !default;
$button-ghost-error-hover-color: map-get($colors, white) !default;
$button-ghost-border-width: $button-border-width !default;
$button-ghost-border-style: $button-border-style !default;
$button-rounded-border-radius: $border-radius-rounded !default;
$button-close-font-weight: $text-font-weight-heavy !default;
$button-close-font-size: 1.4em !default;
$button-icon-left-padding: $spacing-small !default;
$button-icon-right-padding: $spacing-small !default;
$button-super-font-size: $text-font-size-super !default;
$button-xlarge-font-size: $text-font-size-xlarge !default;
$button-large-font-size: $text-font-size-large !default;
$button-medium-font-size: $text-font-size-medium !default;
$button-small-font-size: $text-font-size-small !default;
$button-xsmall-font-size: $text-font-size-xsmall !default;

//
// Calendars
//
$calendar-padding: $spacing-xsmall !default;
$calendar-max-width: 400px !default;
$calendar-background-color: map-get($colors, white) !default;
$calendar-color: map-get($colors, black) !default;
$calendar-text-align: center !default;
$calendar-border-width: $border-width !default;
$calendar-border-style: $border-style !default;
$calendar-border-color: $border-color !default;
$calendar-border: $calendar-border-width $calendar-border-style $calendar-border-color !default;
$calendar-border-radius: $border-radius !default;
$calendar-control-padding: $spacing-medium $spacing-small !default;
$calendar-control-background-color: map-get($colors, white) !default;
$calendar-control-color: map-get($colors, default) !default;
$calendar-control-date-in-month-color: map-get($colors, black) !default;
$calendar-control-font-size: $text-font-size-medium !default;
$calendar-control-border-width: $border-width !default;
$calendar-control-border-style: $border-style !default;
$calendar-control-border-color: $border-color !default;
$calendar-control-border: $calendar-control-border-width $calendar-control-border-style $calendar-control-border-color !default;
$calendar-control-border-hover: $calendar-control-border !default;
$calendar-control-border-radius: $border-radius !default;
$calendar-control-selected-background-color: map-get($colors, brand) !default;
$calendar-control-selected-color: map-get($colors, white) !default;
$calendar-control-selected-border-color: map-get($colors, brand) !default;
$calendar-header-padding: $spacing-small 0 !default;
$calendar-day-font-weight: $text-font-weight-heavy !default;
$calendar-day-padding: $spacing-small 0 !default;
$calendar-today-border-color: map-get($colors, grey-light) !default;

//
// Cards
//
$card-margin: $spacing-small 0 0 0 !default;
$card-width: 100% !default;
$card-max-height: 280px !default;
$card-background-color: map-get($colors, white) !default;
$card-z-index: $z-over-content !default;
$card-box-shadow: $box-shadow !default;
$card-border-radius: $border-radius !default;

$card-image-padding: $spacing-medium 0 0 !default;
$card-header-padding: $spacing-medium $spacing-medium 0 !default;
$card-body-padding: $spacing-medium !default;
$card-footer-padding: $spacing-medium !default;
$card-footer-block-padding: $spacing-small 0 0 !default;

$card-item-padding: $spacing-small !default;
$card-item-border-width: $border-width !default;
$card-item-border-style: $border-style !default;
$card-item-border-color: rgba(map-get($colors, grey-light), .5) !default;
$card-item-border-bottom: $card-item-border-width $card-item-border-style $card-item-border-color !default;

$card-item-hover-background-color: map-get($colors, grey-lighter) !default;
$card-item-hover-color: map-get($colors, grey-lighter) !default;

$card-item-active-background-color: $card-item-border-color !default;
$card-item-active-font-weight: $text-font-weight-heavy !default;

$card-item-disabled-opacity: .6 !default;

$card-item-divider-background-color: map-get($colors, grey-dark) !default;
$card-item-divider-color: map-get($colors, white) !default;
$card-item-divider-font-weight: $text-font-weight-heavy !default;

$card-item-brand-background-color: map-get($colors, brand) !default;
$card-item-info-background-color: map-get($colors, info) !default;
$card-item-warning-background-color: map-get($colors, warning) !default;
$card-item-success-background-color: map-get($colors, success) !default;
$card-item-error-background-color: map-get($colors, error) !default;

$card-item-brand-color: map-get($colors, white) !default;
$card-item-info-color: map-get($colors, white) !default;
$card-item-warning-color: map-get($colors, white) !default;
$card-item-success-color: map-get($colors, white) !default;
$card-item-error-color: map-get($colors, white) !default;

$card-item-brand-hover-background-color: map-get($colors, brand-light) !default;
$card-item-info-hover-background-color: map-get($colors, info-light) !default;
$card-item-warning-hover-background-color: map-get($colors, warning-light) !default;
$card-item-success-hover-background-color: map-get($colors, success-light) !default;
$card-item-error-hover-background-color: map-get($colors, error-light) !default;

$card-item-brand-active-background-color: map-get($colors, brand-dark) !default;
$card-item-info-active-background-color: map-get($colors, info-dark) !default;
$card-item-warning-active-background-color: map-get($colors, warning-dark) !default;
$card-item-success-active-background-color: map-get($colors, success-dark) !default;
$card-item-error-active-background-color: map-get($colors, error-dark) !default;

$card-accordion-chevron-border: 2px solid !default;
$card-accordion-chevron-size: .75em !default;

//
// Drawers
//
$drawer-width-horizontal: 80% !default;
$drawer-width-vertical: 260px !default;
$drawer-background-color: map-get($colors, white) !default;
$drawer-color: map-get($colors, black) !default;
$drawer-border-radius: $border-radius !default;
$drawer-z-index: $z-over-everything !default;
$drawer-heading-padding: $spacing-medium !default;
$drawer-body-padding: $spacing-small !default;
$drawer-footer-padding: $spacing-small !default;
$drawer-footer-block-padding: 0 !default;
$drawer-footer-block-button-border-radius: 0 !default;

//
// Headings
//
$heading-margin: 0 !default;
$heading-padding: $spacing-medium 0 $spacing-small !default;
$heading-font-weight: $text-font-weight !default;
$heading-subheading-padding: 0 !default;
$heading-subheading-opacity: .6 !default;
$heading-subheading-font-size: $text-font-size-medium * .8 !default;

//
// Hints
//
$hint-padding: 0 $spacing-small !default;
$hint-color: map-get($colors, grey-darker) !default;
$hint-font-size: $text-font-size-medium !default;
$hint-success-color: map-get($colors, success) !default;
$hint-error-color: map-get($colors, error) !default;

//
// Inputs
//
$label-padding: $spacing-medium 0 !default;
$label-field-margin: $spacing-small 0 0 0 !default;
$field-padding: $spacing-small !default;
$field-margin: 0 !default;
$field-color: inherit !default;
$field-background-color: map-get($colors, white) !default;
$field-font-size: $text-font-size-medium !default;
$field-font-size-super: $text-font-size-super !default;
$field-font-size-xlarge: $text-font-size-xlarge !default;
$field-font-size-large: $text-font-size-large !default;
$field-font-size-medium: $text-font-size-medium !default;
$field-font-size-small: $text-font-size-small !default;
$field-font-size-xsmall: $text-font-size-xsmall !default;
$field-font-weight: $text-font-weight !default;
$field-border-width: $border-width !default;
$field-border-style: $border-style !default;
$field-border-color: $border-color !default;
$field-border: $field-border-width $field-border-style $field-border-color !default;
$field-border-radius: $border-radius !default;
$field-focus-border-color: $border-color-focus !default;
$field-focus-box-shadow: $box-shadow-focus !default;
$field-disabled-background-color: map-get($colors, grey-lighter) !default;
$field-disabled-border-color: $border-color !default;
$field-disabled-color: map-get($colors, disabled) !default;
$field-error-border-color: map-get($colors, error) !default;
$field-error-color: map-get($colors, error) !default;
$field-success-border-color: map-get($colors, success) !default;
$field-success-color: $field-color !default;
$checkbox-margin: $spacing-tiny !default;
$checkbox-font-size: $text-font-size-medium !default;
$checkbox-background-color: transparent !default;
$checkbox-border: 0 !default;
$fieldset-padding: 0 !default;
$fieldset-margin: $spacing-small 0 !default;
$fieldset-width: 100% !default;
$fieldset-border: 0 !default;
$legend-padding: $spacing-xsmall 0 !default;
$form-element-padding: $spacing-medium 0 !default;
$form-element-label-padding: 0 0 $spacing-small 0 !default;

//
// Links
//
$link-color: map-get($colors, blue-dark) !default;
$link-brand-color: map-get($colors, brand) !default;
$link-info-color: map-get($colors, info) !default;
$link-warning-color: map-get($colors, warning) !default;
$link-success-color: map-get($colors, success) !default;
$link-error-color: map-get($colors, error) !default;
$link-text-decoration: none !default;
$link-hover-text-decoration: underline !default;

//
// Lists
//
$list-margin: 0 !default;
$list-padding: 0 0 0 $spacing-medium !default;
$list-unstyled-padding: 0 !default;
$list-unstyled-list-style: none !default;
$list-nested-padding: $list-padding !default;
$list-item-padding: 0 !default;
$list-item-unstyled-list-style: none !default;
$list-ordered-item-padding: 0 $spacing-small 0 0 !default;
$list-inline-padding: 0 !default;
$list-inline-item-padding-right: $spacing-medium !default;
$list-inline-item-bullet-content: "\2022" !default;
$list-inline-item-bullet-padding: 0 $spacing-small 0 0 !default;

//
// Medias
//
$media-image-width: $avatar-medium-size !default;
$media-body-margin-left: $spacing-small !default;

//
// Modals
//
$modal-width: 80% !default;
$modal-background-color: map-get($colors, white) !default;
$modal-border-width: 0 !default;
$modal-border-style: $border-style !default;
$modal-border-color: $border-color !default;
$modal-border: $modal-border-width $modal-border-style $modal-border-color !default;
$modal-border-radius: $border-radius !default;
$modal-z-index: $z-over-everything !default;
$modal-header-text-align: center !default;
$modal-body-padding: $spacing-medium !default;
$modal-footer-padding: $spacing-medium !default;
$modal-footer-block-padding: $spacing-large 0 0 0 !default;
$modal-footer-block-button-border-radius: 0 !default;
$modal-ghost-background-color: transparent !default;
$modal-ghost-color: map-get($colors, white) !default;
$modal-ghost-heading-color: map-get($colors, white) !default;

//
// Navs
//
$nav-height: 3.5em !default;
$nav-width: 100% !default;
$nav-padding: 0 !default;
$nav-margin: 0 !default;
$nav-background-color: map-get($colors, black) !default;
$nav-color: map-get($colors, white) !default;
$nav-z-index: $z-over-page !default;
$nav-light-background-color: map-get($colors, beige) !default;
$nav-light-color: map-get($colors, brown-darker) !default;
$nav-item-padding: $spacing-medium !default;
$nav-item-letter-spacing: 1px !default;
$nav-item-hover-background-color: map-get($colors, grey-darker) !default;
$nav-item-hover-color: map-get($colors, white) !default;
$nav-item-brand-hover-background-color: map-get($colors, brand) !default;
$nav-item-brand-hover-color: map-get($colors, white) !default;
$nav-item-info-hover-background-color: map-get($colors, info) !default;
$nav-item-info-hover-color: map-get($colors, white) !default;
$nav-item-warning-hover-background-color: map-get($colors, warning) !default;
$nav-item-warning-hover-color: map-get($colors, white) !default;
$nav-item-success-hover-background-color: map-get($colors, success) !default;
$nav-item-success-hover-color: map-get($colors, white) !default;
$nav-item-error-hover-background-color: map-get($colors, error) !default;
$nav-item-error-hover-color: map-get($colors, white) !default;
$nav-item-active-background-color: map-get($colors, grey-darker) !default;
$nav-item-active-color: map-get($colors, white) !default;
$nav-item-brand-active-background-color: map-get($colors, brand) !default;
$nav-item-brand-active-color: map-get($colors, white) !default;
$nav-item-info-active-background-color: map-get($colors, info) !default;
$nav-item-info-active-color: map-get($colors, white) !default;
$nav-item-warning-active-background-color: map-get($colors, warning) !default;
$nav-item-warning-active-color: map-get($colors, white) !default;
$nav-item-success-active-background-color: map-get($colors, success) !default;
$nav-item-success-active-color: map-get($colors, white) !default;
$nav-item-error-active-background-color: map-get($colors, error) !default;
$nav-item-error-active-color: map-get($colors, white) !default;

//
// Overlays
//
$overlay-background-color: map-get($colors, black) !default;
$overlay-z-index: $z-over-screen !default;
$overlay-transparent-background-color: transparent !default;

//
// Pagination
//
$pagination-padding: $spacing-medium !default;
$pagination-width: 100% !default;
$pagination-font-size: $text-font-size-small !default;
$pagination-control-color: map-get($colors, brand) !default;
$pagination-control-border-radius: $border-radius-rounded !default;
$pagination-page-color: map-get($colors, brand) !default;
$pagination-page-current-background-color: map-get($colors, brand) !default;
$pagination-page-current-color: map-get($colors, white) !default;
$pagination-ellipsis-padding: 0 $spacing-medium !default;

//
// Panels
//
$panel-nav-top: ($nav-item-padding * 2) + ($text-line-height * 1em) !default;
$panel-nav-bottom: $panel-nav-top !default;

//
// Progress
//
$progress-background-color: map-get($colors, grey-lighter) !default;
$progress-color: $color-white !default;
$progress-border: 0 !default;
$progress-border-radius: $border-radius !default;
$progress-rounded-border-radius: $border-radius-rounded !default;
$progress-bar-background-color: $color-default !default;
$progress-bar-text-align: center !default;

//
// Ranges
//
$range-width: 100% !default;
$range-height: 10px !default;
$range-padding: $spacing-small 0 !default;
$range-background-color: map-get($colors, default) !default;
$range-brand-background-color: map-get($colors, brand) !default;
$range-info-background-color: map-get($colors, info) !default;
$range-warning-background-color: map-get($colors, warning) !default;
$range-success-background-color: map-get($colors, success) !default;
$range-error-background-color: map-get($colors, error) !default;
$range-border-width: $border-width !default;
$range-border-style: $border-style !default;
$range-border-color: transparent !default;
$range-border: 0 !default;
$range-border-radius: $border-radius-rounded !default;
$range-box-shadow: none !default;
$range-disabled-background-color: map-get($colors, grey-lighter) !default;
$range-thumb-height: 20px !default;
$range-thumb-width: 20px !default;
$range-thumb-margin: -5px 0 0 0 !default;
$range-thumb-background-color: map-get($colors, white) !default;
$range-thumb-border-width: $border-width !default;
$range-thumb-border-style: $border-style !default;
$range-thumb-border-color: transparent !default;
$range-thumb-border: 0 !default;
$range-thumb-border-radius: $border-radius-rounded !default;
$range-thumb-box-shadow: 0 1px 4px -1px map-get($colors, black) !default;
$range-thumb-focus-border-color: $border-color-focus !default;
$range-thumb-focus-box-shadow: $box-shadow-focus !default;

//
// Tables
//
$table-width: 100% !default;
$table-margin: 0 !default;
$table-padding: 0 !default;
$table-border-width: $border-width !default;
$table-border-style: $border-style !default;
$table-border-color: $border-color !default;
$table-border: 0 !default;
$table-caption-padding: $spacing-small 0 !default;
$table-caption-color: map-get($colors, quiet) !default;
$table-caption-max-width: 100% !default;
$table-caption-font-size: $text-font-size-small !default;
$table-caption-text-align: left !default;
$table-cell-padding: $spacing-small !default;
$table-cell-text-align: left !default;
$table-heading-background-color: map-get($colors, grey-light) !default;
$table-heading-color: initial !default;
$table-heading-border-width: $border-width !default;
$table-heading-border-style: $border-style !default;
$table-heading-border-color: map-get($colors, grey) !default;
$table-heading-border: $table-heading-border-width $table-heading-border-style $table-heading-border-color !default;
$table-heading-font-size: $text-font-size-medium * 1.05 !default;
$table-heading-font-weight: $text-font-weight-heavy !default;
$table-heading-striped-color: initial !default;
$table-heading-striped-background-color: map-get($colors, white) !default;
$table-row-striped-color: initial !default;
$table-row-striped-background-color: map-get($colors, grey-lighter) !default;
$table-row-clickable-color: $link-color !default;
$table-row-clickable-background-color: initial !default;
$table-row-disabled-color: map-get($colors, disabled) !default;
$table-row-disabled-background-color: initial !default;
$table-condensed-font-size: $text-font-size-small !default;
$table-condensed-cell-padding: $spacing-xsmall !default;

//
// Tabs
//
$tab-headings-text-align: center !default;
$tab-heading-padding: $spacing-medium !default;
$tab-heading-margin: 0 !default;
$tab-heading-box-shadow-color: map-get($colors, grey-lighter) !default;
$tab-heading-active-box-shadow-color: map-get($colors, default) !default;
$tab-heading-brand-active-box-shadow-color: map-get($colors, brand) !default;
$tab-heading-info-active-box-shadow-color: map-get($colors, info) !default;
$tab-heading-warning-active-box-shadow-color: map-get($colors, warning) !default;
$tab-heading-success-active-box-shadow-color: map-get($colors, success) !default;
$tab-heading-error-active-box-shadow-color: map-get($colors, error) !default;
$tab-heading-disabled-color: map-get($colors, disabled) !default;
$tab-heading-disabled-background-color: initial !default;
$tab-padding: $spacing-medium !default;

//
// Tags
//
$tags-width: 100% !default;
$tags-text-align: left !default;
$tag-padding: $spacing-small $spacing-large $spacing-small $spacing-small !default;
$tag-margin: $spacing-tiny !default;
$tag-close-color: map-get($colors, grey-lighter) !default;
$tag-close-font-weight: $text-font-weight-heavy !default;
$tags-container-width: 70% !default;
$tags-field-container-margin: $spacing-tiny !default;
$tags-field-container-width: 30% !default;

//
// Toggles
//
$toggle-margin: 0 .5em !default;
$toggle-track-background-color: map-get($colors, default) !default;
$toggle-track-brand-background-color: map-get($colors, brand) !default;
$toggle-track-info-background-color: map-get($colors, info) !default;
$toggle-track-warning-background-color: map-get($colors, warning) !default;
$toggle-track-success-background-color: map-get($colors, success) !default;
$toggle-track-error-background-color: map-get($colors, error) !default;
$toggle-track-border-radius: $border-radius-rounded !default;
$toggle-track-unchecked-background-color: map-get($colors, grey-lighter) !default;
$toggle-track-disabled-background-color: map-get($colors, grey-lighter) !default;
$toggle-handle-background-color: map-get($colors, white) !default;
$toggle-handle-border-radius: $border-radius-rounded !default;
$toggle-handle-box-shadow: 0 1px 4px -1px map-get($colors, black) !default;
$toggle-handle-disabled-background-color: map-get($colors, grey-lighter) !default;

//
// Tooltips
//
$tooltip-z-index: $z-over-page !default;
$tooltip-arrow-width: .6em !default;
$tooltip-body-padding: $spacing-small $spacing-medium !default;
$tooltip-body-background-color: map-get($colors, black) !default;
$tooltip-body-color: map-get($colors, white) !default;
$tooltip-line-height: 1.45 !default;
$tooltip-body-border-width: 1px !default;
$tooltip-body-border-style: solid !default;
$tooltip-body-border-color: $tooltip-body-background-color !default;
$tooltip-body-border-radius: $border-radius !default;

//
// Trees
//
$tree-padding: 0 !default;
$tree-margin: 0 !default;
$tree-nested-padding: 0 0 0 $spacing-medium !default;
$tree-item-padding: 0 !default;
$tree-item-indicator-padding: 0 $spacing-small 0 0 !default;
$tree-item-indicator-color: map-get($colors, grey-light) !default;
$tree-item-expandable-indicator-color: map-get($colors, grey) !default;
$tree-item-expanded-indicator-color: map-get($colors, grey-darker) !default;

//
// Trees
//
 
$base-border-color: gainsboro !default;
$base-border-radius: 3px !default;
$base-line-height: 1.5em !default;
$base-background-color: white !default;
$dark-gray: #333 !default;
$large-screen: 53.75em !default;
$base-font-color: $dark-gray !default;
$dropdown-color: $base-font-color;
$dropdown-description-color: lighten($dropdown-color, 40%);
$dropdown-border: 1px solid silver;
$dropdown-background: $base-background-color;
$dropdown-background-hover: darken($dropdown-background, 3%);
$dropdown-inner-border: 1px solid silver;
$dropdown-height: 40px;
$dropdown-padding: 1em;
$dropdown-distance-from-menu: 50px;
$dropdown-arrow-top-distance: 0;