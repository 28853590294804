.news{
	@include wrapper();	

	&__title{
		@extend %section-title;
		@extend %title-vertical;
	}

	&__list{
		display: flex;
		flex-flow: column wrap;
		margin-bottom: 60px;
	}

	&__item{
		width: 80%;

		@include media-max($break_lg){
			width: 100%;
		}

		&:last-child{
			margin-bottom: 0;
		}

		&:nth-child(1){
			align-self: flex-end;
		}

		&:nth-child(2){
			align-self: center;
		}

		&:nth-child(3){
			align-self: flex-start;
		}

	}

	&__btn{
		text-align: center;
	}

}