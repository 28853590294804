.lang {
	$selector: &;
	@extend %dropdown;
	position: relative;

	&.dropdown-show{
		
		#{$selector}__dropdown{
			max-height: 200px !important;
			display: block;
		}
	}

	&__selected {
		@extend %dropdown__selected;
		line-height: 46px;
		@include media-max($break_xs){
			text-align: center;
			padding: 0;
		}
	}

	&__dropdown {
		@extend %dropdown__menu;
		background-color: $accent-color;
		border:2px solid darken($accent-color, 10%);
		border-top: none;
		top: 100%;
		left: 0;
		display: none;

		@include media-max($break_xs){
			top: 80%;
			left: 50%;
		}

		li{			
			margin: 0;			
		}
		a{
			line-height: 36px;
			padding: 0 8px;
			display: block;
			&:hover{
				color: #fff;
				background-color: $main-color;
				text-decoration: none;
			}
		}
	}
}