// =============================================================================
// Custom variables
// =============================================================================


// Fonts
$main-font-family: 'OpenSans';
$accent-font-family: 'EtelkaMediumPro';
$main-font-size: 16px;

// Colors
$text-color: #333;
$main-color: #002e5b;
$accent-color: #fde428;
$success-color: #3c763d;
$error-color: #a94442;
$disable-color: #D2D2D2;
$color-tint: 9 !default;

// Link
$link-color: $main-color;
$link-color-hover: lighten($main-color, 30%);
$link-color-focus: darken($main-color, 30%);


// Grid
$break_lg: 1200px;
$break_md: 992px;
$break_sm: 720px;
$break_xs: 576px;

// Menu
$mobile-switch: $break_md;
$menu-height: 100px;

// Button
$button-background-color: $accent-color;
$button-color: #000;
$button-padding: 10px 50px;
$button-font-size: rem(14px);

//Form
$field-focus-border-color: $accent-color;
$border-color-focus: $accent-color;
$box-shadow-focus: inset 0 0 0 2px lighten($border-color-focus, $color-tint);

// Breadcrumbs
$breadcrumb-crumb-separator-content: '';
$breadcrumb-padding: 10px 16px;
$breadcrumb-crumb-separator-color: #727272;
$breadcrumb-margin: 0 0 40px 0;

// Pagination
$pagination-control-color: #827D7D;
$pagination-control-border-radius: 0;
$pagination-page-current-background-color: $main-color;