//  Headings

%h1, %h2, %h3, %h4, %h5, %h6,
h1, h2, h3, h4, h5, h6{
	color: $text-color;
  margin: 0 0 rem(10px) 0;
}

%h1, h1{
  font-size: rem(36px);
  line-height: 1.05;
  @include media-max($break_sm) {
    font-size: rem(30px);
  }
}

%h2, h2{
  font-size: rem(30px);
  line-height: 1.14;
  @include media-max($break_sm) {
    font-size: rem(26px);
  }
}

%h3, h3{
  font-size: rem(24px);
  line-height: 1.38;
  @include media-max($break_sm) {
    font-size: rem(20px);
  }
 }

%h4, h4{
  font-size: rem(18px);
  line-height: 1.38;
  font-size: rem(18px);
  @include media-max($break_sm) {
    font-size: rem(16px);
  }
 }

%h5, h5{
	font-size: rem(14px);
	line-height: 1.2;
}

%h6, h6{
  font-size: rem(12px);
  line-height: 1.2;
}

// Basic Text Style

%p, p{
  margin: 0 0 rem(14px) 0;
}

a{
  color: $link-color;
  transition: color .1s;
  &:hover{
    text-decoration: underline;
    color: $link-color-hover;
  }
  &:focus{
    color: $link-color-focus;
  }
}

%mark, mark {
  background-color: $accent-color;
  padding: rem(2px);
}

%blockquote, blockquote{
  border-left: 5px solid $accent-color;
  padding: 10px 20px;
}


em{
  font-style: italic;
}

hr{
  border: solid $accent-color;
  border-width: 1px 0 0;
  clear: both;
  margin: 10px 0 30px;
  height: 0;
}


// List

ul,
ol {
  margin-top: 0;
  ul,
  ol {
    margin-bottom: 0;
  }
}


li {
  line-height: 1.3;
  margin-bottom: rem(5px);
}

// Description Lists
dl {
  margin-top: 0;
  line-height: 1.2;
}
dt,
dd {  
}
dt {
  font-weight: bold;
  margin: rem(8px) 0 rem(2px) 0;
}
dd {
  margin-left: 4px;
  font-size: rem(15px);
}