.eurostatics{
	@include wrapper();
	position: relative;

	&__row{
	}

	&__title{
		@extend %section-title;
		@extend %title-vertical;
	}

	&__mission{
		width: 26%;
		margin-left: 60px;

		@include media-max($break_md){
			width: auto;
			margin-left: 0;
		}
	}

	&__target{
		width: 30%;
		margin-left: 400px;
		margin-top: 60px;

		@include media-max($break_md){
			width: auto;
			margin-left: 0;
		}
	}

}