// Mixins
@mixin button-color($background-color: $button-background-color, $color: $button-color) {
  border: $button-border;
  background-color: $background-color;
  color: $color;

  &.c-button--active {
    background-color: darken($background-color, $color-tint);
  }

  &:not(:disabled) {
    &:hover {
      background-color: lighten($background-color, $color-tint);
      color: lighten(#000, $color-tint);
      text-decoration: none;
    }

    &:focus {
      border-color: $button-focus-border-color;
      box-shadow: $button-focus-box-shadow;
    }

    &:active {
      background-color: darken($background-color, $color-tint);
    }
  }
}

@mixin button-ghost($color: $button-ghost-color, $color-hover: $button-ghost-hover-color) {
  border: $button-ghost-border-width $button-ghost-border-style $color;
  background-color: transparent;
  color: $color;

  &.c-button--active {
    border-color: darken($color, $color-tint);
    background-color: darken($color, $color-tint);
    color: $color-hover;
  }

  &:not(:disabled) {
    &:hover {
      background-color: $color;
      color: $color-hover;
    }

    &:focus {
      border-color: $button-focus-border-color;
      box-shadow: $button-focus-box-shadow;
    }

    &:active {
      border-color: darken($color, $color-tint);
      background-color: darken($color, $color-tint);
      color: $color-hover;
    }
  }
}

@mixin button {
  @include button-color();
  display: inline-block;
  max-width: 100%;
  margin: $button-margin;
  padding: $button-padding;
  border-radius: $button-border-radius;
  outline: 0;
  font-family: $button-font-family;
  font-size: $button-font-size;
  line-height: $button-line-height;
  text-align: $button-text-align;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: $button-white-space;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  appearance: none;
  user-select: none;
  font-weight: bold;

  &:disabled {
    cursor: not-allowed;
    opacity: $button-disabled-opacity;
  }
}

.c-button {
  @include button;
}

.c-button--close {
  @include button-color(transparent, inherit);
  position: absolute;
  right: $spacing-small;
  padding: 0;
  outline: 0;
  font-size: $button-close-font-size;
  font-weight: $button-close-font-weight;
  line-height: 1;
}

.c-button--block {
  display: inline-block;
  width: 100%;
}

.c-button--rounded {
  border-radius: $button-rounded-border-radius;
}

.c-button--brand {
  @include button-color($button-brand-background-color, $button-brand-color);
}

.c-button--info {
  @include button-color($button-info-background-color, $button-info-color);
}

.c-button--warning {
  @include button-color($button-warning-background-color, $button-warning-color);
}

.c-button--success {
  @include button-color($button-success-background-color, $button-success-color);
}

.c-button--error {
  @include button-color($button-error-background-color, $button-error-color);
}

.c-button--ghost {
  @include button-ghost;
}

.c-button--ghost-brand {
  @include button-ghost($button-ghost-brand-color, $button-ghost-brand-hover-color);
}

.c-button--ghost-info {
  @include button-ghost($button-ghost-info-color, $button-ghost-info-hover-color);
}

.c-button--ghost-warning {
  @include button-ghost($button-ghost-warning-color, $button-ghost-warning-hover-color);
}

.c-button--ghost-success {
  @include button-ghost($button-ghost-success-color, $button-ghost-success-hover-color);
}

.c-button--ghost-error {
  @include button-ghost($button-ghost-error-color, $button-ghost-error-hover-color);
}

.c-button__icon-left {
  padding-right: $button-icon-left-padding;
}

.c-button__icon-right {
  padding-left: $button-icon-right-padding;
}