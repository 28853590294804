%section{
	padding-top: 100px;
	padding-bottom: 100px;
	position: relative;
}

%page-wrap{
	padding-top: 30px;
}

%section-title{
	font-size: rem(24px);
	text-transform: uppercase;
	color: $main-color;
	margin-bottom: 20px;
	line-height: rem(30px);

	@include media-max($break_xs){
		font-size: 20px;
	}

}

%title-vertical{
	transform: rotate(90deg);
  transform-origin: 13px 10px 0;
  position: absolute;

	@include media-max($break_lg){
		position: static;
		transform: none;
	}

}